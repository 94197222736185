import React from "react"
import NavigationBar from "../components/navigation_bar"
import SEO from '../components/seo';

export default function Privacy() {
  return (
    <div>
      <SEO title="Privacy Policy"/>
      <NavigationBar current="/privacy" />
      <div className="container">
        <div className="display-4 my-5">Privacy Policy</div>
        <div className="pb-5">
          This Privacy Policy governs the ways in which Tapl Solar (we or our)
          collects, uses, maintains and discloses information collected from our
          customers, visitors to our websites, and visitors to our customer's
          websites (Users). In the course of providing services to Users,
          whether they are customers, visitors to our websites, or visitors to
          our customer's websites, we collect information. Each User has a
          different definition of what information they consider to be private.
          The purpose of our Privacy Policy is to discuss how we handle the
          majority of information we collect, or which comes into our
          possession. Simply because information is discussed in this policy
          does not mean it is necessarily considered by us to be private. Our
          Privacy Policy supplements, but does not replace, our Terms of Service
          and Acceptable Use Policy. You may find these policies useful in
          learning how we handle information. If at any time, you would like to
          know how specific information is handled, please email us at
          support@taplsolar.com. Information Collection Cookies. Our website
          uses cookies. Cookies are small data files that are placed
          automatically on a User's computer by our computers. You can set your
          browser to notify you when you receive a cookie, giving you the chance
          to decide whether to accept it. Our site uses the following cookies:
          *.TaplSolar.com Registration, Billing and Customer Records. When Users
          become customers they may provide us with their name, address, phone
          number, user name, email address, domain group membership, security
          questions/answers, financial information and other personally
          identifiable or confidential information. This information may be
          aggregated for license reporting and billing purposes (collectively,
          Registration and Billing Information). Customer Inquiries. If you
          contact us about a problem or with a question we may ask you for
          specific information related to the reason you have contacted us
          (Customer Inquiry Information). This information may be tied or input
          into your Registration or Billing Information. Statistical
          Information. All of our servers collect statistical information such
          as your IP address, date you visited, computer you are using, and
          operating system, among other data (Statistical Information). Use of
          Collected Information General Information. All information, either
          alone or when combined with other information, can be processed in
          such a way as to identify Users individually. This section describes
          how we use various categories of information, and whether we use it,
          or process it, in ways which can identify a User individually.
          Cookies. We use the information provided by the cookies to change the
          way our website looks to you; to keep you logged in to certain areas
          of the website; and to collect demographic data about where visitors
          to our website go and how they behave. Cookies allow us to track
          repeated visits to the website and connect each visit with information
          voluntarily provided by the visitor. For example, when the visitor
          voluntarily provides us with their name, company name, and email
          address, we will connect that information to the visitor when they
          visit the website at a later date. Tapl Solar does not sell
          individually identifiable information collected on our websites.
          Registration and Billing Information. We use Registration and Billing
          Information to identify Users individually. Registration and Billing
          Information is used throughout our business to provide services to
          you, and to market new Tapl Solar products and services to you. Some
          Registration and Billing Information, such as email addresses, phone
          numbers and security questions/answers, are used for password reset
          and maintenance notifications. User names and domain group memberships
          are collected for license reporting purposes. Information related to
          payment is only used to ensure we receive payment for services
          rendered. It may be shared with third parties affiliated with us if
          they provide services through us to you and those services are billed
          by them independently. Currently, no Registration and Billing
          Information is disclosed outside of Tapl Solar other than a customer's
          name, and such information is only disclosed for the purpose of
          reporting license usage to third-party vendors. Customer Inquiries. We
          use Customer Inquiry Information to identify Users individually. It is
          used throughout our business to provide services to you, and to market
          new Tapl Solar products and services to you. Customer Inquiry
          Information is only shared with third parties in order to identify and
          fix problems with services when we are not capable of doing so
          ourselves. When Customer Inquiry Information is shared with third
          parties in this way, the third parties are required to keep this
          information confidential. Statistical Information. We use statistical
          information to operate our hardware and software, diagnose problems,
          administer our website and secure hosted systems. We may use aggregate
          information to modify the products and services we offer based on who
          is visiting our website, and what they are purchasing. We do not use
          this data to identify Users individually. Other Disclosures. Tapl
          Solar is required to comply with relevant laws, regulations and
          judicial orders. Information covered by this Privacy Policy, as well
          as other categories of information, may be disclosed to third parties
          if we determine, in our sole discretion, that such a disclosure is
          necessary or required. We may also disclose this information if, in
          our sole discretion, such a disclosure is necessary or required to
          protect our interests or the interests of our customers. We may also
          disclose this information in connection with the sale of our business.
          Options and Inquiries To modify or update your Registration and
          Billing Information please contact us via telephone at one of the
          numbers listed on our website, TaplSolar.com. Data Security No means
          of communication or information transmission or storage is totally
          secure. However, we protect the information set out in this Privacy
          Policy using industry standard security measures. Only approved
          employees are permitted access to the User information. Unless
          disclosure is authorized as set out above, such approved employees are
          required to keep User information confidential. In the unlikely event
          of unauthorized access, we cannot be responsible for loss, corruption
          or acquisition and use of information, or for any damages resulting
          therefrom, including unauthorized acquisition and use. Third-Party
          Services, Content, and External Links You may access third-party
          content through the Tapl Solar websites and navigate to third-party
          sites and services from links on our websites. Whether the third-party
          content appears within our websites (such as in an embedded video
          player), or you leave our websites to view the content on another
          website, the third party is in control of and independently produces,
          maintains, and monitors the content and third-party sites. When you
          watch third-party content that is made available through our websites
          or navigate to such third-party sites, you become subject to the third
          party's terms of use and privacy policies. You should review the
          privacy policies of these third-party sites for their policies and
          practices regarding the collection and use of your information as
          their policies may differ from ours. We do not accept any
          responsibility or liability for the privacy practices of third
          parties. Changes To This Privacy Policy We may revise this Privacy
          Policy, for example, to reflect changes in the law, our practices, or
          the features of the Services. These changes will be effective
          immediately upon posting. As a result, you should periodically review
          the Privacy Policy so that you are up to date on our most current
          policies and practices. By using our websites, you agree to the terms
          and conditions of this Privacy Policy, and, by continuing to use our
          websites after changes have been made, you agree to the changes.
        </div>
      </div>
    </div>
  )
}
